import Vue from "vue";
import i18n from '../i18n'
import store from "../store";

export default class common {
  static formatDate (date) {
    let time = this.formatAMPM(date)
    return time
  }

  static  formatAMPM(date) {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    let strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  }

  static scrollHeight(){

    setTimeout(()=>{
      let scrollElement = document.querySelector('.botter-modal-chat')
      if (scrollElement)
        scrollElement.scrollTop = scrollElement.scrollHeight + 50
    },50)

    setTimeout(()=>{
      let scrollElement = document.querySelector('.botter-modal-chat')
      if (scrollElement)
        scrollElement.scrollTop = scrollElement.scrollHeight + 50
    },2000)
  }

  static get_yt_iframe_from_url(url) {

      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return (match && match[2].length === 11)
        ? match[2]
        : null;

  }

  static formatText (text) {
    let newText = this.replaceSpecialChars(text,'*', '<b>','</b>')
      newText = this.replaceSpecialChars(newText,'_', '<i>','</i>')
      newText = this.replaceSpecialChars(newText,'~', '<del>','</del>')
    try {
      newText = this.linkify(newText)
      newText = this.replacePhoneNumbers(newText)
    }
    catch (e) {
      console.log(e)
    }

    return newText
  }

  static replaceSpecialChars(text,char,replaceStart, replaceEnd) {
    let count = 0

    for (let i=0; i<text.length; i++) {
      if (text.charAt(i) === char) {
          count++
          if (count % 2 === 0) {
            text = this.replaceCharIndex(text,i,replaceEnd)
          } else {
            text = this.replaceCharIndex(text,i,replaceStart)
          }
      }
    }

    if (count % 2 !== 0 && count > 0)  {

      let lastIndex = text.lastIndexOf(replaceStart)
      text = text.slice(0, lastIndex) + char + text.slice(lastIndex + replaceStart.length,text.length)
    }

    return text
  }

  static replaceCharIndex(string,index, replacement) {
    return (string.substr(0, index)) + replacement + (string.substr(index + 1));
  }

  // replace phone numbers to phone call link
  static replacePhoneNumbers (inputText) {
    let replacedText, replacePattern1;

    replacePattern1 = /(\+?\d[\d -]{8,12}\d)/gim;
    replacedText = inputText.replace(replacePattern1, '<a href="tel:$1" class="botter-hyber-link">$1</a>');

    return replacedText;
  }

  static linkify (inputText) {
    let replacedText, replacePattern1, replacePattern2, replacePattern3;

    //URLs starting with http://, https://, or ftp://
    replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank" class="botter-hyber-link">$1</a>');

    //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" class="botter-hyber-link" target="_blank">$2</a>');

    //Change email addresses to mailto:: links.
    replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z0-9\-\_\.]+?(\.[a-zA-Z]{2,6})+)/gim;
    replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1" class="botter-hyber-link">$1</a>');

    return replacedText;
  }

  static updateQueryStringParam(key, value) {
    key = encodeURIComponent(key);
    value = encodeURIComponent(value);

    // kvp looks like ['key1=value1', 'key2=value2', ...]
    var kvp = document.location.search.substr(1).split('&');
    let i=0;

    for(; i<kvp.length; i++){
      if (kvp[i].startsWith(key + '=')) {
        let pair = kvp[i].split('=');
        pair[1] = value;
        kvp[i] = pair.join('=');
        break;
      }
    }

    if(i >= kvp.length){
      kvp[kvp.length] = [key,value].join('=');
    }

    // can return this or...
    let params = kvp.join('&');

    // reload page with new params
    document.location.search = params;
  }

  static setCookie(key, value, ttl) {
    key = `${key}_${store.state.core.botId}`
    const now = new Date()
    const item = {
      value: value,
      expiry: now.getTime() + (ttl * 24 * 60 * 60 * 1000)
    }
    localStorage.setItem(key, JSON.stringify(item))
  }

  static getCookie(key) {
    key = `${key}_${store.state.core.botId}`
    const itemStr = localStorage.getItem(key)
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null
    }
    const item = JSON.parse(itemStr)
    const now = new Date()
    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key)
      return null
    }
    return item.value
  }

  static getTextAlignment (text) {
    let arabic = /[\u0600-\u06FF]/
    let english = /[a-zA-Z]/


    for (let i = 0; i < text.length; i++) {
      if (arabic.test(text.charAt(i))) {
        return 'botter-rtl'
      } else if (english.test(text.charAt(i))) {
        return  'botter-ltr'
      }

    }

    return  'botter-ltr'

  }

  static isEmptyObj(obj) {
    for(var prop in obj) {
      if(obj.hasOwnProperty(prop))
        return false;
    }
    return true;
  }

  static convertObjToArr (Obj) {
    let arr= []
    for (let key in Obj) {
      if (Obj.hasOwnProperty(key)) {
        arr.push({
          attribute: key,
          value: Obj[key]
        })
      }
    }
    return arr
  }

  static delete_cookie(name) {
    document.cookie = name +'=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
  }

  static deserializeQueryUrl() {
    let search = location.search.substring(1);
    if (search) {
      try {
        return JSON.parse('{"' + decodeURIComponent(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
      }
      catch (e) {
        return {}
      }

    }
    return {}
  }

  static  timeAgo(dateParam) {
    if (!dateParam) {
      return null;
    }

    const date = typeof dateParam === 'object' ? dateParam : new Date(dateParam);
    const DAY_IN_MS = 86400000; // 24 * 60 * 60 * 1000
    const today = new Date();
    const yesterday = new Date(today - DAY_IN_MS);
    const seconds = Math.round((today - date) / 1000);
    const minutes = Math.round(seconds / 60);
    const isToday = today.toDateString() === date.toDateString();
    const isYesterday = yesterday.toDateString() === date.toDateString();
    const isThisYear = today.getFullYear() === date.getFullYear();


    if (seconds < 5) {
      return i18n.tc('about a minute ago');
    } else if (seconds < 60) {
      return i18n.tc(`about a minute ago`);
    } else if (seconds < 90) {
      return i18n.tc('about a minute ago');
    } else if (minutes < 60) {
      return `${ minutes } ${i18n.tc('minutes ago')}`;
    } else if (isToday) {
      return this.getFormattedDate(date, i18n.tc(`Today`)); // Today at 10:20
    } else if (isYesterday) {
      return this.getFormattedDate(date, i18n.tc(`Yesterday`)); // Yesterday at 10:20
    } else if (isThisYear) {
      return this.getFormattedDate(date, false, true); // 10. January at 10:20
    }

    return this.getFormattedDate(date); // 10. January 2017. at 10:20
  }

  static getFormattedDate(date, prefomattedDate = false, hideYear = false) {
    const MONTH_NAMES = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    const day = date.getDate();
    const month = MONTH_NAMES[date.getMonth()];
    const year = date.getFullYear();
    const hours = date.getHours();
    let minutes = date.getMinutes();

    if (minutes < 10) {
      // Adding leading zero to minutes
      minutes = `0${ minutes }`;
    }

    if (prefomattedDate) {
      // Today at 10:20
      // Yesterday at 10:20
      return `${ prefomattedDate } ${i18n.tc('at')} ${ hours }:${ minutes }`;
    }

    if (hideYear) {
      // 10. January at 10:20
      return `${ day }. ${ month } ${i18n.tc('at')} ${ hours }:${ minutes }`;
    }

    // 10. January 2017. at 10:20
    return `${ day }. ${ month } ${ year }. ${i18n.tc('at')} ${ hours }:${ minutes }`;
  }


  // static sanitizeString(str){
  //   str = str.replace(/[^a-z0-9áéíóúñü \.,_-]/gim,"");
  //   return str.trim();
  // }

  static sanitizeString(string) {
    const map = {
      '&': '',
      '<': '',
      '>': '',
      '"': '',
      "'": '',
      "/": '',
    };
    //const reg = /[&<>"'/]/ig;
    return string
    //return string.replace(reg, (match)=>(map[match]));
  }

}
