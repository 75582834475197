<template>
  <div class="botter-btn">
    <slot/>
  </div>
</template>

<script>
  export default {
    name: "b-btn"
  }
</script>

<style scoped lang="scss">
  .botter-btn {
    cursor: pointer;
    border: 1px solid var(--primaryColor);
    padding: 7px 15px;
    border-radius: 5px;
    color: var(--primaryColor);
    font-size: 13px;
    text-align: center;
  }
  .botter-btn:hover {
   background: #eee;
  }
</style>
